<template>
  <v-container fluid>
    <v-toolbar
      color="transparent"
      class="my-4"
      :dark="this.$store.state.darkTheme ? true : false"
    >
      <v-toolbar-title>
        <h1
          v-if="!isMobile()"
          class="font-weight-light"
        >
          Join a Perseus project
        </h1>
        <h2
          v-if="isMobile()"
          class="font-weight-light"
        >
          Join a project
        </h2>
      </v-toolbar-title>
    </v-toolbar>

    <v-select
      v-model="project2join"
      :items="this.$store.state.allActiveProjects"
      label="Select the project you want to join"
      :dark="this.$store.state.darkTheme ? true:false"
      full-width
      clearable
      clear-icon="fas fa-times"
      append-icon="fas fa-chevron-down"
    />

    <v-card-actions>
      <v-btn
        :dark="this.$store.state.darkTheme ? true:false"
        large
        text
        :icon="isMobile()"
        color="gred"
        to="/my-projects"
      >
        <v-icon>mdi-arrow-left</v-icon>
        <span v-if="!isMobile()"> return to project page </span>
      </v-btn>
      <v-spacer />
      <v-btn
        :dark="this.$store.state.darkTheme ? true:false"
        large
        color="green"
        :disabled="!project2join"
        @click="sendJoinRequest"
      >
        Send a join request
        &thinsp;
        <v-icon>fas fa-paper-plane</v-icon>
      </v-btn>
    </v-card-actions>

    <v-card
      v-if="project2join"
      :dark="this.$store.state.darkTheme ? true:false"
      :style="style_cardinfo"
      elevation="0"
      outlined
    >
      <v-card-title>
        Main information about {{ project.name.value.slice(3) }} project
      </v-card-title>
      <!-- Informations principales du projet -->
      <v-card-text>
        <tr
          v-for="(info,ki) in project"
          :key="ki"
          class="text-left"
        >
          <th
            v-if="!(info.title.includes('Admin')||info.title.includes('Member'))"
          >
            {{ info.title }}
          </th>
          &emsp;
          <td
            v-if="!(info.title.includes('Admin')||info.title.includes('Member'))"
          >
            {{ info.value }}
          </td>
        </tr>
      </v-card-text>
      <!-- Liste des admins du projet -->
      <v-card-text>
        <th>{{ project.admins.title }}</th>
        <tr
          v-for="(admin,ka) in project.admins.value"
          :key="ka"
        >
          <td />
            &emsp;
          <td>{{ admin.cn }}</td>
            &emsp;
          <td>{{ admin.uid }}</td>
        </tr>
      </v-card-text>
      <!-- Liste des membres du projet -->
      <v-card-text>
        <th>{{ project.members.title }}</th>
        <tr
          v-for="(member,km) in project.members.value"
          :key="km"
        >
          <td />
            &emsp;
          <td>{{ member.cn }}</td>
            &emsp;
          <td>{{ member.uid }}</td>
        </tr>
      </v-card-text>
    </v-card>

    <!-- Boite de dialogue : Reponse serveur -->
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title>
          Server answer
          <v-spacer />
          <v-btn
            color="primary"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          {{ answerServer }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import config from '@/config'
  import axios from 'axios'
  import checkToken from '@/store/checkToken'

  function formatDate (date) {
    var d = new Date(date)
    var month = '' + (d.getMonth() + 1)
    var day = '' + d.getDate()
    var year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    return [day, month, year].join('/')
  }

  export default {
    data: () => ({
      blue: config.colors.gricad_oceanblue,
      red: config.colors.gricad_red_dark1,

      project2join: null,
      currentProject: null,
      dialog: false,
      answerServer: '',

      project: {
        name: { title: 'Project name', value: '' },
        lab: { title: 'Laboratory', value: '' },
        begin: { title: 'Start of project', value: '' },
        end: { title: 'End of project', value: '' },
        description: { title: 'Description', value: '' },
        admins: { title: 'Admin(s) of project', value: [] },
        members: { title: 'Member(s) of project', value: [] },
      },

    }),

    computed: {
      style_form () {
        return {
          width: `${this.$store.state.windowW * (2 / 3)}px`,
          'background-color': 'transparent',
        }
      },
      style_cardinfo () {
        return {
          'background-color': 'transparent',
          margin: '50px 10px 50px 10px',
          'font-size': '30px',
        }
      },
    },

    created () {
      // init la liste des projets actifs
      var token = $cookies.get('token')
      var validToken = checkToken(token)
      if (!validToken) {
        this.$router.push('/login')
      } else {
        if (this.$store.state.allActiveProjects.length === 0) {
          this.$store.state.allActiveProjects = []
          axios
            .get('/getall-activesProjects')
            .then(response => {
              var listprojects = response.data
              for (var i = 0; i < listprojects.length; i++) {
                var project = listprojects[i]
                if (project !== 'pr-admin') {
                  this.$store.state.allActiveProjects.push(project)
                }
              }
              this.$store.state.allActiveProjects.sort()
            })
        }
      }
    },

    beforeUpdate () {
      if (this.currentProject !== this.project2join) {
        this.currentProject = this.project2join
        if (this.project2join) {
          this.updateData()
        }
      }
    },

    methods: {
      isMobile () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
      closeDialog () {
        this.dialog = false
        this.answerServer = ''
        this.$router.push('/my-projects')
      },
      sendJoinRequest () {
        // TODO : ajout du membre au projet avec add_member_project(cn, uid)
        // renvoi sur la page projet ? => this.$router.push('/my-projects')
        // ou rester sur cette page ?
        // FIXME : Temporaire en attendant les envois de mail
        /* if (this.project2join.includes('joinme')) {
          var token = $cookies.get('token')
          var validToken = checkToken(token)
          if (!validToken) {
            this.$router.push('/login')
          } else {
            axios.get('/project/' + this.project2join + '/add-member/' + this.$store.state.account.login).then(response => {
              console.log(response.data)
              if (String(response.data).includes('ERROR')) {
                console.log('Join project ' + this.project2join + ' SUCCESS')
              } else {
                console.log('Join project ' + this.project2join + ' FAILS')
              }
            })
          } */
        var token = $cookies.get('token')
        var validToken = checkToken(token)
        if (!validToken) {
          this.$router.push('/login')
        } else {
          axios.get('/project/' + this.project.name.value + '/join-request/' + this.$store.state.account.login)
            .then(response => {
              if (response.data.includes('SUCCESS')) {
                this.dialog = true
                this.answerServer = response.data + '. '
                this.answerServer += 'Please wait the admins to add you in this project.'
              }
              else if(response.data.includes('ERROR')) {
                this.dialog = true
                this.answerServer = response.data 
                }
            })
        }
      },

      // Reinitialisation des donnees
      resetData () {
        this.project.name.value = ''
        this.project.lab.value = ''
        this.project.begin.value = ''
        this.project.end.value = ''
        this.project.description.value = ''
        this.project.admins.value = []
      },

      // Mise a jour des donnes
      updateData () {
        // Reinitialisation des listes
        this.project.admins.value = []
        this.project.members.value = []
        // Recuperation du nom de projet
        var projectName = this.project2join
        this.project.name.value = projectName
        // tests
        // Requete axios : recuperation des infos de projet
        axios
          .get('/project/' + projectName)
          .then(response => {
            var localProject = response.data
            this.project.description.value = localProject.description[0]
            axios
              .get('/project/' + projectName + '/get-lab')
              .then(response => {
                this.project.lab.value = response.data.slice(2)
              })
            this.project.begin.value = formatDate(Number(localProject.creationDate[0] * 1000))
            if (localProject.validityDate != null) {
              this.project.end.value = formatDate(Number(localProject.validityDate[0] * 1000))
            }
            localProject.adminUid.forEach(admin => {
              axios
                .get('/username/' + admin + '/get-cn')
                .then(response => {
                  this.project.admins.value.push({
                    uid: admin,
                    cn: response.data,
                  })
                })
            })
            localProject.memberUid.forEach(member => {
              axios
                .get('/username/' + member + '/get-cn')
                .then(response => {
                  this.project.members.value.push({
                    uid: member,
                    cn: response.data,
                  })
                })
            })
          })
      },
    },
  }
</script>
